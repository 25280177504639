<template>
    <v-menu offset-y left rounded="lg" v-model="menu">
        <template v-slot:activator="{ on }">
            <v-btn
                    v-show="typeof show !== 'undefined' ? show : true"
                    color="secondary"
                    dark
                    v-on="on"
                    class="rounded-lg"
                    small
                    :height="medium ? 36 : 30"
                    :loading="loading"
                    :block="layout === 'cart'"
            >
                <v-row align="center" dense>
                    <v-col style="max-width: 24px">
                    </v-col>
                    <v-col>
                        <div class="paragraph font-weight-bold">{{ $t('ItemActions.title') }}</div>
                    </v-col>
                    <v-col class="pr-0" cols="auto">
                        <v-icon>mdi-menu-down</v-icon>
                    </v-col>
                </v-row>
            </v-btn>
        </template>

        <v-list dense>
            <template v-for="(item, index) in actions_array">
                <div class="caption-2 pa-3 label--text" v-if="item.header" :key="item.header">{{ item.header }}</div>
                <v-list-item
                        v-else-if="inItem ? item.position === 'item' && item.show : item.position === display && item.show"
                        :key="item.title"
                        link
                        @click.stop="clickAction(item)"
                >
                    <v-list-item-icon class="mr-2">
                        <v-icon small>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <div class="caption-1 pr-2">{{ item.label }}</div>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>

        <Dialog v-model="dialog" :title="$t('single_trad.Calendar.dialog_title')" @close="dialog = false">
            <template v-slot:content>
                <ActionInfos :item="action.infos" v-if="typeof action.infos !== 'undefined'" />
                <FieldGroup
                        v-if="typeof action.fields !== 'undefined' && Object.keys(action.fields).length > 0"
                        v-model="action._POST"
                        :fields="action.fields"
                        :path="path + '/' + action.action_name"
                        :pevents="pevents"
                        mode="creation"
                        @created="(e) => afterExec(e)"
                        @formOk="(e) => form_ok = e"
                        @loading="(e) => loading = e"
                />
            </template>
        </Dialog>
        <Dialog
                v-model="confirm_dialog"
                type="confirm"
                :message="$tc('ItemActions.dialog_v.message', 1, {action: action.label})"
                @confirm="execAction()"
                @cancel="confirm_dialog = false"
                :loading="loading"
        />
    </v-menu>
</template>

<script>
    export default {
        name: "Actions",
        props: {
            actions: {},
            methods: {type: Array, default: () => []},
            path: {},
            inItem: {},
            checkout: {type: Boolean, default: false},
            noExtras: {type: Boolean},
            layout: {},
            show: {},
            pevents: {},
            display: {},
            medium: {type: Boolean}
        },
        components: {
            Dialog: () => import('@/components/ui/Dialog.vue'),
            Button: () => import('@/components/ui/Button.vue'),
            ActionInfos: () => import('@/components/ui/ActionInfos.vue'),
            FieldGroup: () => import('@/components/FieldGroup.vue')
        },
        data() {
            return {
                actions_array: [],
                actions_array_backend: [],
                dialog: false,
                action: {},
                confirm_dialog: false,
                form_ok: false,
                loading: false,
                menu: false
			}
        },
        watch: {
            display() {
                this.makeActionsArray()
            }
        },
        methods: {
            afterExec(e){
                this.loading = false
                this.$emit('execAction', e)

                if(this.action.next !== null){
                    this.getAction(this.action.next)
                }
                else {
                    this.$store.dispatch('snackbar/success', this.$tc('snackbar.ItemActions.execAction.success', 1, {name: this.action.label}))
                    this.dialog = false
                    this.getActionsArrayBack()
                }
            },
            clickAction(item){
                this.action = item

                if(item.click !== null) {
                    this.$nextTick(item.click)
                }
                else {
                    if(item.enabled){
                        if(Object.keys(item.fields).length === 0 && Object.keys(item.infos).length === 0){
                            this.execAction(item.action_name)
                        }
                        else {
                            this.openDialog(item)
                        }
                    }
                    else{
                        if(Object.keys(item.infos).length !== 0) {
                            this.openDialog(item)
                        }
                    }
                }

                this.menu = false
            },
            openDialog(action){
                this.action = action
                this.$set(this.action, '_POST', {})
                this.dialog = true
                this.loading = false
            },
            execAction(action_name){
                this.loading = true

                this.$wsc.post(this.path + '/' + (action_name ? action_name : this.action.action_name), this.action._POST, success => {
                    this.getActionsArrayBack()
                    this.confirm_dialog = false
                    this.dialog = false
                    this.loading = false
                    this.$emit('execAction', success)

                    if(this.pevents !== null) {
                        if (this.action.refresh) {
                            this.pevents.notify('refresh')
                        } else {
                            this.pevents.notify('update')
                        }
                    }

                    this.$store.dispatch('snackbar/success', this.$tc('snackbar.ItemActions.execAction.success', 1, {name: action_name}))
                }, fail => {
                    this.loading = false
                }, {
                    responseType: this.action.mimetype
                })
            },
            getActions() {
                let res = []

                if (!this.checkout){
                    if (this.inItem){
                        if (this.$store.getters["auth/contact_info"].super_user){
                            res.push({
                                position: 'item',
                                enabled: true,
                                icon: 'mdi-arrow-all',
                                label: this.$tc('ItemActions.fieldgroups', 1),
                                info: {},
                                fields: {},
                                show: true,
                                next: null,
                                click: () => this.$emit('fieldgroups')
                            },
                            {
                                position: 'item',
                                enabled: true,
                                icon: 'mdi-view-grid',
                                label: this.$tc('ItemActions.items', 1),
                                info: {},
                                fields: {},
                                show: true,
                                next: null,
                                click: () => this.$emit('items')
                            })
                        }
                        if(this.methods.includes('update')){
                            res.push({
                                position: 'item',
                                enabled: true,
                                icon: 'mdi-pencil',
                                label: this.$tc('global.action.modify', 1),
                                info: {},
                                fields: {},
                                show: true,
                                next: null,
                                click: () => this.$emit('modify')
                            })
                        }

                        if(this.methods.includes('destroy')){
                            res.push({
                                position: 'item',
                                enabled: true,
                                icon: 'mdi-delete',
                                label: this.$tc('global.action.delete', 1),
                                info: {},
                                fields: {},
                                show: true,
                                next: null,
                                click: () => this.$emit('delete')
                            })
                        }

                        if(!this.noExtras) {
                            res.push({
                                position: 'item',
                                enabled: true,
                                icon: 'mdi-plus',
                                label: this.$tc('global.name.extra_fields', 1),
                                info: {},
                                fields: {},
                                show: true,
                                next: null,
                                click: () => this.$emit('extras')
                            })
                        }
                    }
                    if(this.display === 'table'){
                        res.push({
                            position: 'table',
                            enabled: true,
                            icon: 'mdi-playlist-plus',
                            label: this.$t('single_trad.Table.filters'),
                            info: {},
                            fields: {},
                            show: true,
                            next: null,
                            click: () => this.$emit('headers')
                        })
                    }
                }
                if (res.length > 0) {
                    res.unshift({header: this.$tc('ItemActions.category')})
                }

                return res
            },
            getActionsArrayBack(){
                let res = []

                if (typeof this.actions !== 'undefined') {
                    let one = true
                    let ready = this.actions.length

                    this.actions.forEach(e => {
                        if (typeof e !== 'object') {
                            if(one && !this.checkout) {
                                res.push({header: this.$tc('ItemActions.custom_category')})
                                one = false
                            }

                            this.$wsc.getItem(this.path, e, success => {
                                success['click'] = null
                                
                                this.$wsc.getOptions(this.$route.params, {}, this.path + '/' + e, suc => {
                                    success['fields'] = suc.fields
                                    success['action_name'] = e
                                    success['position'] = this.inItem ? 'item': this.display

                                    res.push(success)

                                    ready--

                                    if(ready === 0){
                                        this.actions_array_backend = res.sort((a,b) => {
                                            if(a.label < b.label){
                                                return -1
                                            }
                                            else if(a.label >= b.label) {
                                                return 1
                                            }
                                            else {
                                                return 0
                                            }
                                        })

                                        this.makeActionsArray()
                                        this.loading = false
                                    }
                                }, fail => {

                                })
                            }, fail => {
                                ready--
                            })
                        }
                        else if(typeof e === 'object'){
                            res.push(e)

                            ready--

                            if(ready === 0){
                                this.actions_array_backend = res.sort((a,b) => {
                                    if(a.label < b.label){
                                        return -1
                                    }
                                    else if(a.label >= b.label) {
                                        return 1
                                    }
                                    else {
                                        return 0
                                    }
                                })

                                this.makeActionsArray()
                                this.loading = false
                            }
                        }
                    })

                    if(this.actions.length === 0){
                        this.makeActionsArray()
                    }
                }
            },
            makeActionsArray(){
                let res = this.getActions()
                this.actions_array = res.concat(this.actions_array_backend)
            },
            getAction(action){
                this.dialog = false
                this.$wsc.getItem(this.path, action, success => {
                    success['click'] = null

                    this.$wsc.getOptions(this.$route.params, {}, this.path + '/' + action, suc => {
                        success['fields'] = suc.fields
                        success['action_name'] = action
                        this.action = success
                        this.clickAction(this.action)
                    }, fai => {

                    })
                }, fail => {

                })
            },
            setUpHooks(){
                this.pevents.wait('display', () => {
                    this.makeActionsArray()
                })

                this.pevents.wait('parent-saved', () => {
                    this.getActionsArrayBack()
                })
            }
        },
		created() {
			this.getActionsArrayBack()
		}
	}
</script>

<style scoped>

</style>
